import React, { createContext, useState, useEffect } from 'react';
import decode from 'jwt-decode';
import { setHeader } from 'src/utils/api';
export const UserContext = createContext();

export const UserProvider = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [overviewFilter, setOverviewFilter] = useState({});
  const checkAuth = () => {
    const token = localStorage.getItem('token');
    setHeader(token);
    if (!token) {
      return false;
    }

    try {
      const { exp } = decode(token);

      if (exp < new Date().getTime() / 1000) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  };
  const getUserAuth = () => {
    setLoading(true);

    const status = checkAuth();
    setIsAuthenticated(status);
    setLoading(false);
  };
  useEffect(() => {
    if (isAuthenticated === null) {
      getUserAuth();
    }
  }, []);
  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        getUserAuth,
        setLoading,
        loading,
        overviewFilter,
        setOverviewFilter
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
