/* eslint-disable arrow-parens */
/* eslint-disable implicit-arrow-linebreak */
import axios from 'axios';
import endpoints from './apiConstants';

const isProductionEnv = process.env.REACT_APP_NODE_ENV === 'production';
const productionBaseURL = process.env.REACT_APP_BASE_URL;
const developmentBaseURL = process.env.REACT_APP_DEV_BASE_URL;
const baseURL = isProductionEnv ? productionBaseURL : developmentBaseURL;
const instance = axios.create({
  baseURL,
  timeout: 60000
});

export const setHeader = token => {
  instance.defaults.headers.common['x-auth-token'] = token;
};
const request = (method, url, data, headers) =>
  new Promise((resolve, reject) => {
    (() => {
      // if (!isProductionEnv) {
      //   instance.defaults.withCredentials = true;
      // }
      if (method === 'get') {
        return instance.request({
          url,
          method,
          params: data,
          headers
        });
      }
      return instance.request({
        url,
        method,
        data,
        headers
      });
    })()
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });

export default {
  get: (endpoint, data, headers = {}) =>
    request('get', endpoint, data, headers),
  post: (endpoint, data, headers = {}) =>
    request('post', endpoint, data, headers),
  put: (endpoint, data, headers = {}) =>
    request('put', endpoint, data, headers),
  patch: (endpoint, data, headers = {}) =>
    request('patch', endpoint, data, headers),
  delete: (endpoint, data, headers = {}) =>
    request('delete', endpoint, data, headers),
  endpoints
};
