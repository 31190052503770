/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { UserContext } from 'src/context/UserContext';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/ResetPassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/create-password',
    component: lazy(() => import('src/views/auth/CreatePassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forget-password',
    component: lazy(() => import('src/views/auth/ForgotPassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },

  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/overview" />
      },

      {
        exact: true,
        path: '/overview',
        component: lazy(() => import('src/views/Overview'))
      },
      // {
      //   exact: true,
      //   path: '/profile',
      //   component: lazy(() => import('src/views/pages/AccountView'))
      // },
      {
        exact: true,
        path: '/leads',
        component: lazy(() => import('src/views/Leads'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
  // {
  //   path: '/docs',
  //   layout: DocsLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/docs',
  //       component: () => <Redirect to="/docs/welcome" />
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/welcome',
  //       component: lazy(() => import('src/views/docs/WelcomeView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/getting-started',
  //       component: lazy(() => import('src/views/docs/GettingStartedView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/environment-variables',
  //       component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/deployment',
  //       component: lazy(() => import('src/views/docs/DeploymentView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/api-calls',
  //       component: lazy(() => import('src/views/docs/ApiCallsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/analytics',
  //       component: lazy(() => import('src/views/docs/AnalyticsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/authentication',
  //       component: lazy(() => import('src/views/docs/AuthenticationView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/routing',
  //       component: lazy(() => import('src/views/docs/RoutingView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/settings',
  //       component: lazy(() => import('src/views/docs/SettingsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/state-management',
  //       component: lazy(() => import('src/views/docs/StateManagementView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/theming',
  //       component: lazy(() => import('src/views/docs/ThemingView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/support',
  //       component: lazy(() => import('src/views/docs/SupportView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/changelog',
  //       component: lazy(() => import('src/views/docs/ChangelogView'))
  //     },
  //     {
  //       component: () => <Redirect to="/404" />
  //     }
  //   ]
  // },
  // {
  //   path: '*',
  //   layout: MainLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/home',
  //       component: HomeView
  //     },
  //     {
  //       exact: true,
  //       path: '/pricing',
  //       component: lazy(() => import('src/views/pages/PricingView'))
  //     },
  //     {
  //       component: () => <Redirect to="/404" />
  //     }
  //   ]
  // }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
