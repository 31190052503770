import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from '../context/UserContext';
function GuestGuard({ children }) {
  // const account = useSelector(state => state.account);
  const { isAuthenticated } = useContext(UserContext);

  if (isAuthenticated) {
    return <Redirect to="/overview" />;
  }
  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
