import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from '../context/UserContext';

function AuthGuard({ children }) {
  const { isAuthenticated } = useContext(UserContext);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
