import api from './api';

export const fetcher = async url => {
  try {
    const res = await api.get(url);
    return res;
  } catch (error) {
    throw error;
  }
};

export const value = {
  dedupingInterval: 5000,
  fetcher
};
