/* eslint-disable arrow-parens */
import { messaging } from 'src/init-fcm.js';
import api from './api';
import apiEndpoint from './apiConstants';

export const pushNotificationTokenSend = async token => {
  try {
    await api.post(`${apiEndpoint.subscribe}`, {
      registration_token: token
    });
  } catch (error) {
    //error handled
  }
};

export default async function pushNotificationPermission() {
  messaging
    .requestPermission()
    .then(async () => {
      const token = await messaging.getToken();
      pushNotificationTokenSend(token);
    })
    .catch(err => {
      console.log('Unable to get permission to notify.', err);
    });
  navigator.serviceWorker.addEventListener('message', message =>
    console.log('notification message', message)
  );
}
