import * as firebase from 'firebase/app';
import 'firebase/messaging';
const initializedFirebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyB2nJHdGpO7BfFHypTMHQzuIbyLJUhf-KA',
  projectId: 'publisher-dashboard-prod',
  messagingSenderId: '765165702970',
  appId: '1:765165702970:web:142544bccb648e4205ca03'
});

let messaging;

if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
}
export { messaging };
